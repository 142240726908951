<template>
  <div id="app">
    <div id="menu" v-if="showHead" :class="{'small':menuMode=='small'}">
      <left-menu ref="leftMenu"></left-menu>
    </div>
    <div id="nav" v-if="showHead" :class="{'nav-small':menuMode=='small'}">
      <i class="iconfont icon-indent-remove-full" @click="changeMenu" :title="menuMode=='default'?'收缩侧边栏':'展开侧边栏'"
         style="margin-right: 22px;"></i>
      <i class="iconfont icon-refresh-line" @click="clickReload" title="刷新"></i>
      <Dropdown transfer trigger="click" style="float: right;margin-left: 20px" @on-click="changeDown">
        <a href="javascript:void(0)">
          admin
          <Icon type="ios-arrow-down"></Icon>
        </a>
        <DropdownMenu slot="list">
          <DropdownItem name="edit">修改</DropdownItem>
          <DropdownItem name="out_login">退出登录</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <div :class="{'main-view':showHead,'main-small':menuMode=='small'}">
      <keep-alive>
        <router-view ref="reload" v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view ref="reload" v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>
<script>
import leftMenu from "../components/public/leftMenu";

export default {
  name: "APP",
  components: {
    leftMenu
  },
  data() {
    return {
      showHead: true,
      menuMode: 'default',
    }
  },
  watch: {
    '$route.path': function(path) {
      this.showHead = path == '/login' ? false : true;
    },
  },
  methods: {
    changeDown(name) {
      var _this = this;
      if (name == 'out_login') {
        _this.$Modal.confirm({
          title: '提示',
          content: '确认退出登录吗？',
          onOk: () => {
            _this.requestApi('/adm/login_out').then(function(res) {
              if (res.status) {
                _this.showHead = false;
                localStorage.removeItem('store_token');
                setTimeout(function() {
                  _this.toUrl('/login');
                }, 500)
                _this.alertSucc(res.msg)
              } else {
                _this.alertErr(res.msg);
              }
            })
          },
        })
      }
    },
    //收缩、展开菜单栏
    changeMenu() {
      this.menuMode = this.menuMode == 'default' ? 'small' : 'default';
    },
    //点击刷新
    clickReload() {
      if (this.$refs.reload.clickReload) this.$refs.reload.clickReload();
      else if (this.$refs.reload.init) this.$refs.reload.init();
    }
  }
}
</script>
<style>
@import "./assets/public/css/index.css";
@import "./assets/public/css/common.css";
@import "//at.alicdn.com/t/font_2601133_l5q3e1h98nf.css";
@import "./assets/iconfont/iconfont.css";
</style>
